.-eighteen {
    font-size: 18px;
    line-height: 29px;
}

.-sixteen {
    font-size: 16px;
    line-height: 20px;
}

.-subtitle {
    color: $black;  
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
}

.-secondaryTitle {
    font-weight: 400;
    margin-top: 20px;
}

.-otherInfo {
    font-weight: 400;
    margin-top: 20px;
    
    a {
        width: fit-content;
        font-size: 18px;
    }
}

.-gray {
    color: $main-link;
}
