.m-selectGroup {
    outline: 0;
    position: relative;
    margin-top: 15px;
    display: flex;
    flex-direction: column;

    label {
        font-size: 13px;
        line-height: 16px;
        font-weight: 600;
    }

    input {
        padding: 0 20px 0 15px;
        height: 44px;
        font-size: 13px;
        border-radius: 3px;
        margin-top: 10px;

        &::placeholder {
            font-size: $font-size-base;
            color: rgba($primary-color, 0.4);
        }
    }

    &.-required {
        label {
            &::after {
                position: relative;
                content: '*';
                color: $red;
                right: -5px;
            }
        }
    }

    &.-disabled {
        opacity: 0.7;

        label {
            color: #828a8d;
        }

        input {
            color: #828a8d;
            background-color: $white;
        }
    }

    &.-custom {
        position: relative;
        z-index: 2;
        width: 100%;
    }

    &__container {
        position: relative;
    }

    &__select {
        width: 100%;
        border: 1px solid $gray-3;
        border-radius: 6px;
        transition: border-color 0.3s;

        &.-open {
            outline: 0;
            border-color: $primary-color;

            ~ .m-selectGroup__arrow {
                transform: translateY(-50%) rotate(225deg);
            }
        }

        &.-hide {
            color: transparent;

            &::placeholder {
                visibility: hidden;
            }
        }

        &.-error {
            border-color: $red;
        }
    }

    &__options {
        position: absolute;
        z-index: 2;
        min-height: fit-content;
        background-color: white;
        border: 1px solid $primary-color;
        border-radius: 3px;
        width: 100%;
        opacity: 0;
        top: 45px;
        pointer-events: none;
        transition: opacity 0.3s, top 0.3s;
        overflow: hidden;
        max-height: 150px;
        overflow-y: auto;
        @include scrollbar;

        &.-open {
            opacity: 1;
            top: 45px;
            pointer-events: initial;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                padding: 5px 10px;
                font-size: 13px;
                cursor: pointer;
                transition: background-color 0.3s;

                &:hover {
                    background-color: $gray-3;
                }
            }
        }
    }

    &__arrow {
        width: 6px;
        height: 6px;
        position: absolute;
        top: 50%;
        right: 10px;
        cursor: pointer;
        border: solid $primary-color;
        border-width: 0 2px 2px 0;
        padding: 2px;
        transform: translateY(-50%) rotate(45deg);
        transition: transform 0.3s, border-color 0.3s;
    }

    &__searchInput {
        width: 100%;
        background-color: transparent;
        outline: 0;
        border: 2px solid transparent;
        white-space: nowrap;
        position: absolute;
        top: 0;
        left: 0;
    }

    &__loader {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $primary-color;
        font-size: 12px;
        margin-top: 10px;

        &:hover {
            background-color: $white !important;
            cursor: default;
        }

        .arrow {
            width: 30px;
            height: 15px;
            display: flex;
            transform: rotate(90deg);
        }
        .arrow:before {
            content: '';
            flex: 1;
            margin: 0 5px;
            background: $primary-color;
            clip-path: polygon(
                0% 40%,
                60% 40%,
                60% 0%,
                100% 50%,
                60% 100%,
                60% 60%,
                0% 60%
            );
            animation: a3 0.5s infinite alternate;
        }

        @keyframes a3 {
            0% {
                transform: scaleX(var(--s, 1)) translate(-4px);
            }
            100% {
                transform: scaleX(var(--s, 1)) translate(4px);
            }
        }
    }
}
