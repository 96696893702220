nav {
    z-index: 1;
    position: fixed;
    top: 0;
    width: 100%;
    background-color: $white;
}

.m-nav {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;

    @include media-down(l) {
        padding-top: 0px;
        padding-bottom: 0px;
        margin-bottom: 15px;
    }

    @include media-down(m) {
        display: flex;
        justify-content: center;
        height: 57px;
        margin-bottom: 0px;
        padding-bottom: 15px;

        &.-auth {
            justify-content: flex-start;
        }

        .js-open & {
            height: 100vh;
        }
    }

    &__logo {
        z-index: 4;
        width: 140px;
        min-height: 40px;
        max-height: 40px;
        background-image: url('../../../images/raiffeisen_logo.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

        @include media-down(l) {
            position: absolute;
            top: 20px;
            left: 50%;
            transform: translateX(-50%);

            &.-auth {
                top: 15px;
            }
        }

        @include media-down(xs) {
            width: 70px;
        }
    }

    &__items {
        display: flex;
        align-items: center;

        @include media-down(m) {
            z-index: 3;
            position: absolute;
            top: 60px;
            flex-direction: column;
            transform: translateX(-350%);
            transition: 0.3s ease-in-out;
            overflow: hidden;
            left: -100vw;
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
                0 1px 1px 0 rgba(0, 0, 0, 0.14),
                0 2px 1px -1px rgba(0, 0, 0, 0.12);
        }

        &.-mobile {
            @include media-down(m) {
                width: 100%;
                max-width: 400px;
                height: 100vh;
                background-color: $white;
                transform: translateX(0);
                left: -20%;
            }
            @include media-down(s) {
                left: 0;
            }
        }

        &--item {
            display: flex;
            align-items: center;
            height: 40px;
            font-size: 16px;
            line-height: 19px;
            white-space: nowrap;

            @include media-down(l) {
                font-size: 14px;
            }

            @include media-down(m) {
                text-align: center;
                margin-top: 30px;
            }

            &.-link {
                position: relative;
                display: flex;
                margin-right: 30px;
                color: $primary-color;

                @include media-down(m) {
                    margin-right: 0;
                }

                &::after {
                    content: '';
                    position: absolute;
                    top: calc(100% - 8px);
                    width: 0;
                    height: 2px;
                    background-color: $red;
                    transition: 0.3s ease-in-out;
                }

                &:hover {
                    cursor: pointer;

                    &::after {
                        width: 100%;
                    }
                }

                &:last-child {
                    margin-right: 0;
                }
            }

            &.-active::after {
                width: 100%;
            }

            a {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .a-button {
            margin-top: 10px;
            margin-bottom: 10px;
            padding: 10px 20px;
        }
    }

    &.-noAuth {
        display: flex;
        justify-content: space-between;

        @include media-down(m) {
            .m-nav__logo {
                position: static;
                transform: none;
            }
        }
    }
}
