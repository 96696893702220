.-padtop100 {
    padding-top: 100px;

    @include media-down(l) {
        padding-top: 50px;
    }
}

.-padtop40 {
    padding-top: 40px;
}

.-padbottom100 {
    padding-bottom: 100px;

    @include media-down(m) {
        padding-bottom: 50px;
    }
}

.-marleft100 {
    @include media-up(xl) {
        margin-left: 100px;
    }
}

.-marbottom10 {
    margin-bottom: 10px;
}

.-background {
    background-image: url('../../../images/application.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: $primary-color;
    height: 1207px;

    @include media-down(m) {
        background-image: url('../../../images/application_small.png');
    }
}

.-order2 {
    @include media-down(l) {
        order: 2;
    }
}
