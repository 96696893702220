.p-notFoundPage {
    text-align: center;

    &__wrapper {
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__text {
        margin-bottom: 20px;
    }

    &__image {
        margin-bottom: 20px;
    }
}
