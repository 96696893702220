// Use $font-path from _vars/_base.scss as path to fonts folder or update it here
// Use https://transfonter.org/ to generate fonts and update font path here
// Default font path (relative to style.css) is ../fonts (that is value of $font-path)

@font-face {
    font-family: 'Figtree';
    src: url('../../fonts/Figtree/Figtree-Light.woff2') format('woff2'),
    url('../../fonts/Figtree/Figtree-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Figtree';
    src: url('../../fonts/Figtree/Figtree-Regular.woff2') format('woff2'),
        url('../../fonts/Figtree/Figtree-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Figtree';
    src: url('../../fonts/Figtree/Figtree-SemiBold.woff2') format('woff2'),
    url('../../fonts/Figtree/Figtree-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Figtree';
    src: url('../../fonts/Figtree/Figtree-Medium.woff2') format('woff2'),
    url('../../fonts/Figtree/Figtree-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('../../fonts/Figtree/Figtree-Bold.woff2') format('woff2'),
    url('../../fonts/Figtree/Figtree-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
