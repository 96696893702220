.m-footer {
    padding-top: 80px;
    padding-bottom: 20px;
    background-color: $white;
    background-image: url('../../../images/footer.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: $primary-color;
    border-top: 1px solid $primary-color;

    @include media-down(l) {
        background-position: left;
    }

    @include media-down(s) {
        padding-top: 50px;
        padding-bottom: 50px;
        background-image: url('../../../images/footer_small.png');
    }

    a {
        width: fit-content;
        position: relative;
        display: flex;
        flex-direction: column;
        color: $primary-color;
        cursor: pointer;

        &::after {
            content: '';
            position: absolute;
            top: calc(100% + 4px);
            width: 0;
            height: 0.5px;
            background-color: $primary-color;
            transition: 0.3s ease-in-out;
        }

        &:hover {
            &::after {
                width: 100%;
            }
        }
    }

    &__column {
        display: flex;
        flex-direction: column;
        height: 100%;

        &.-marTop {
            @include media-down(l) {
                margin-top: 30px;
            }

            @include media-down(m) {
                margin-top: 0;
            }
        }

        &.-main {
            @include media-down(l) {
                margin-bottom: 30px;
            }

            @include media-down(s) {
                margin-bottom: 0;
            }
        }

        @include media-down(s) {
            align-items: center;
            text-align: center;
        }

        &--logo {
            margin-bottom: 20px;

            img {
                min-height: 32px;
                max-height: 32px;

                @include media-down(m) {
                    min-height: auto;
                }

                @include media-down(s) {
                    min-height: 32px;
                }
            }
        }

        &--links {
            display: flex;
            flex-direction: column;

            a,
            span {
                margin-top: 13px;
            }

            @include media-down(s) {
                align-items: center;
            }

            p {
                margin-top: 13px;
                margin-bottom: 0px;
            }
        }

        .-mainLink {
            margin-top: 3px;
            color: $main-link;

            @include media-down(s) {
                margin-top: 30px;
            }
        }

        .-copy {

            font-weight: 600;

            @include media-down(s) {
                max-width: 200px;
            }
        }
    }

    &__row {
        display: flex;
        height: 100%;
        column-gap: 3rem;

        @include media-down(s) {
            flex-direction: column;
        }

        a {
            margin-bottom: 12px;

            @include media-down(s) {
                margin: 0 auto;
            }
        }

        p {
            @include media-down(s) {
                margin: 0 auto 12px auto;
            }
        }
    }
}
