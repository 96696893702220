.m-project {
    &__top {
        display: flex;
        justify-content: space-between;
        flex-direction: column-reverse;
        margin: 20px 0;
        align-items: center;

        @include media-up(m) {
            flex-direction: row;
            margin: 58px 0;
        }

        h2 {
            margin-top: 30px;
        }
    }

    &__info {
        h6 {
            font-size: $font-size-small;
            font-weight: 400;
        }
        p {
            margin-top: 10px;
            font-size: 18px;
            font-weight: 300;
            line-height: 1.6;
        }

        &.-general {
            margin-left: 0px;
            margin-right: 0px;
        }
    }

    &__wrapper {
        margin: 0 auto;
    }

    &__draftBack {
        display: flex;
        align-items: center;
        margin: 10px 0;

        &:hover {
            color: $secondary-color;
            cursor: pointer;
            svg {
                stroke: $secondary-color;
            }
        }

        svg {
            margin-right: 10px;
        }
    }

    .-videoWaitingMessage {
        padding-top: 100px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    &.-video {
        margin: 0 auto;

        h4 {
            text-align: center;
            margin-top: 90px;
        }

        button {
            margin: 40px auto 0;
        }

        .m-project__content {
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            &--wrapper {
                position: relative;
                padding-bottom: 56.25%;
                width: 100%;
                height: 0;
                margin-top: 50px;
            }
        }

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
