.m-adminHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $white;
    color: $white;
    border-bottom: 1px solid $gray-2;
    transition: 0.5s;
    padding-left: 120px;
    position: fixed;
    height: $header-height;
    width: 100%;
    top: 0;
    z-index: 3;

    &.-topLevel {
        right: 0;
        padding-left: 22px;
        padding-right: 15px;
        border-bottom: none;
        box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.2),
            0px 2px 2px 0px rgba(0, 0, 0, 0.14),
            0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    }

    &__logo {
        @include media-down(l) {
            order: 2;
        }

        img {
            height: 15px;

            &.-companyLogo {
                height: 40px;
            }
        }
    }

    &__nav {
        display: flex;
        align-items: center;
    }

    &__main {
        order: 2;
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    .a-chevron {
        border-right-color: $white;
        border-bottom-color: $white;
    }
}
