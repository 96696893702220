.m-inputGroup {
    position: relative;
    margin-top: 15px;
    display: flex;
    flex-direction: column;

    label {
        font-weight: 600;
    }

    &.-password {
        input {
            padding-left: 59px;

            &[type='password'] {
                letter-spacing: 4px;

                &::placeholder {
                    letter-spacing: initial;
                }
            }
        }
    }

    &.-icon {
        input {
            padding-left: 44px;
        }

        &.-label {
            svg {
                top: 38px;
            }
        }
    }

    &.-error {
        input {
            border-color: $red;

            &:focus {
                border-color: $red;
            }
        }
    }

    &.-hasValue {
        input {
            background-color: $white;
        }
    }

    &.-disabled {
        opacity: 0.7;

        label {
            color: #828a8d;
        }

        input {
            color: #828a8d;
        }
    }

    &.-notbold {
        label {
            font-weight: 400;
        }
    }

    input {
        width: 100%;
        height: 44px;
        margin-top: 10px;
        padding: 0 15px 0 15px;
        border-radius: 3px;
        border: 1px solid $gray-3;
        background-color: $white;
        outline: 0;
        color: $black;
        font-size: $font-size-small;
        transition: border-color 0.3s, background-color 0.3s;
        filter: none;

        &:invalid {
            box-shadow: none;
        }

        &:focus {
            background-color: $white;
            border-color: $primary-color;

            ~ svg {
                stroke: $primary-color;
            }
        }

        &::placeholder {
            opacity: .5;
            color: $primary-color;
            font-weight: 300;
            font-size: $font-size-base;
        }
    }

    input:placeholder-shown {
        text-overflow: ellipsis;
    }

    &.-required {
        label {
            &::after {
                position: relative;
                content: '*';
                color: $red;
                right: -5px;
            }
        }
    }

    label {
        font-size: $font-size-small;
        line-height: 16px;
        .a-require-star {
            color: $red;
        }
    }

    svg {
        position: absolute;
        left: 10px;
        top: 45px;
        transition: stroke 0.3s;
    }

    &__error {
        display: block;
        color: $red;
        opacity: 0;
        font-size: 13px;
        transition: opacity 0.3s;
        letter-spacing: -0.2px;
        line-height: 16px;
        margin-top: 3px;

        &.-active {
            opacity: 1;
        }
    }
}
