.m-faq {
    background-color: $white;

    &__main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &--title {
            margin-top: 15px;
            text-align: center;
        }

        .-faq {
                text-decoration: underline;
                cursor: pointer;
                transition: 0.3s ease-in-out;
    
                &:hover {
                    color: $light-blue;
                }
        }
    }

    &__accordions {
        margin-top: 20px;

        &--accordion {
            margin-top: 10px;
            border: 1px solid $border-color;
            border-radius: 3px;
            transition: 0.3s ease-in-out;

            &:hover {
                background-color: $gray-2;

                .-plus {
                    transform: scale(1.3);
                }
            }

            .-accordionBtn {
                display: flex;
                align-items: baseline;
                width: 100%;
                outline: none;
                border-style: none;
                padding: 12px 35px 12px 20px;
                background-color: transparent;
                text-align: left;
                font-weight: 400;
                font-size: 20px;
                line-height: 30px;
                transition: 0.4s ease-in-out;
                cursor: pointer;

                @include media-down(m) {
                    padding: 15px 15px 10px;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                }

                .-plus {
                    position: relative;
                    width: 12px;
                    height: 12px;
                    min-width: 12px;
                    min-height: 12px;
                    margin-right: 20px;
                    transition: 0.3s ease-in-out;

                    span {
                        position: absolute;
                        top: 6px;
                        width: 12px;
                        height: 1px;
                        background-color: $primary-color;
                        transition: 0.3s ease-in-out;
                    }

                    span:first-of-type {
                        transform: rotate(-90deg);
                    }

                    span:nth-of-type(2) {
                        transform: rotate(0deg);
                    }
                }

                &:focus {
                    outline: none;
                }

                &.js-active {
                    .-plus {
                        span:first-of-type {
                            transform: rotate(0deg);
                        }

                        span:nth-of-type(2) {
                            transform: rotate(180deg);
                        }
                    }
                }
            }

            .-content {
                max-height: 0;
                margin-left: 55px;
                margin-right: 15px;
                overflow: hidden;
                transition: 0.3s ease-in-out;

                &.js-content {
                    max-height: 100%;
                }
            }
        }
    }
}
