.a-button {
    border-style: none;
    border-radius: 3px;
    background-color: transparent;
    color: $white;
    font-size: 16px;
    line-height: 19px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    width: 180px;
    padding-top: 15px;
    padding-bottom: 15px;

    &:active,
    &:focus {
        outline: none;
    }

    &.-primary,
    &.-primaryDisabled {
        border: 1px solid transparent;
        background-color: $primary-color;
        color: $white;

        &:not(.-primaryDisabled) {
            &:hover {
                border: 1px solid $primary-color;
                background-color: $white;
                color: $primary-color;
            }
        }
    }

    &.-secondary,
    &.-secondaryDisabled {
        background-color: $secondary-color;
        color: $white;

        svg {
            stroke: $white;
        }

        &:not(.-secondaryDisabled) {
            &:hover {
                background-color: rgba($secondary-color, 0.5);
                color: $white;
            }
        }
    }

    &.-basic,
    &.-basicDisabled {
        background-color: transparent;
        color: $primary-color;
    }

    &.-primaryDisabled {
        background-color: $gray-2;
        color: $primary-light-color;
        cursor: default;
    }

    &.-basicDisabled {
        color: $primary-light-color;
        cursor: default;

        svg {
            stroke: $primary-light-color;
        }
    }

    &.-secondaryDisabled {
        color: $primary-light-color;
        cursor: default;
    }

    &.-buttonWithIcon {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;

        @include media-down(xl) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &.-exportImport {
        width: 100px;
    }

    &.-vote {
        width: fit-content;
        padding-left: 15px;
        padding-right: 15px;
    }

    &.-apply {
        width: fit-content;
        padding-left: 15px;
        padding-right: 15px;
    }

    &__icon {
        display: flex;
        align-items: center;
        color: $primary-color;
        width: 100%;

        svg {
            margin-right: 10px;
        }
    }

    &.-tertiary {
        background-color: transparent;
        color: $red;
    }

    &.-loadMore {
        text-decoration: underline;
        color: $black;
    }
}
