.m-dateTimePicker {
    margin-top: 15px;
    label {
        font-size: 13px;
        line-height: 16px;
        font-weight: 600;
        .a-require-star {
            color: $red;
        }
    }
    input {
        width: 100%;
        height: 44px;
        padding: 0 15px 0 15px;
        border-radius: 3px;
        border: 1px solid $gray-3;
        background-color: $white;
        outline: 0;
        color: $primary-color;
        font-size: 13px;
        transition: border-color 0.3s, background-color 0.3s;
        filter: none;

        &::placeholder {
            font-size: $font-size-base;
            color: $primary-color;
            opacity: 0.4;
        }
    }

    &.-error {
        input {
            border: 1px solid $color-error;

            &:focus {
                border: 1px solid $primary-color;
                outline: none;
            }
        }
    }

    .a-errorMessage {
        font-size: $font-size-small;
        color: $red;
    }
}

.flatpickr-calendar {
    &.hasTime {
        height: 70px;

        .flatpickr-time {
            position: relative;
            height: 72px !important;
            max-height: none;
    

            .numInputWrapper {
                margin-top: 30px;
            }
    
            .flatpickr-time-separator {
                margin-top: 30px;
                height: auto;
            }
            .flatpickr-minute {
                font-weight: bold;
            }
    
            input {
                font-weight: bold;
            }
    
            &::before {
                content: 'Hours';
                position: absolute;
                top: 0;
                width: 50%;
                height: 30px;
                padding-left: 11px;
                color: $white;
                background-color: $primary-color;
                border: 1px solid $gray-3;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
            }
    
            &::after {
                content: 'Minutes';
                position: absolute;
                top: 0;
                right: 0;
                width: 50%;
                height: 30px;
                padding-left: 11px;
                color: $white;
                background-color: $primary-color;
                border: 1px solid $gray-3;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
    &.arrowTop {
        &::after {
            border-bottom-color: $primary-color !important;
        }
    }

    &.arrowBottom {
        &::after {
            border-top-color: $primary-color !important;
        }
    }
    .flatpickr-month {
        padding-top: 2px;
        background-color: $primary-color !important;
        border-radius: 4px 4px 0 0 !important;

        .flatpickr-monthDropdown-months {
            background-color: $primary-color !important;

            .flatpickr-monthDropdown-month {
                background-color: $primary-color !important;

                &:hover {
                    background-color: $primary-color !important;
                    transition: 0.3s;
                }
            }
        }
    }

    .flatpickr-next-month {
        &:hover {
            svg {
                fill: $primary-color !important;
                transition: 0.3s;
            }
        }
    }
    .flatpickr-innerContainer {
        // display: none;
        .flatpickr-weekdays {
            background-color: $primary-color !important;

            .flatpickr-weekday {
                color: $white !important;
                background-color: $primary-color !important;
            }
        }

        .flatpickr-days {
            .flatpickr-day {
                margin-top: 5px;
                border-radius: 4px !important;
                &.prevMonthDay {
                    color: rgba(72, 72, 72, 0.3);
                    background-color: transparent;
                }
                &.nextMonthDay {
                    color: rgba(72, 72, 72, 0.3);
                    background-color: transparent;
                }

                &.today {
                    transition: 0.3s;

                    &:hover {
                        color: #484848;
                        border: 1px solid transparent;
                        transition: 0.3s;
                    }
                }

                &.selected {
                    color: $white;
                }
                &:hover {
                    background-color: $primary-color !important;
                    color: $white;
                    border: 1px solid transparent;
                    transition: 0.3s;
                }
                &.selected {
                    background-color: $primary-color !important;
                    border: none;
                }
            }
        }
    }
}
.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
    background-color: $primary-color !important;
}
.flatpickr-day.endRange {
    background-color: $primary-color !important;
    border-color: transparent !important;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.inRange {
    box-shadow: -5px 0 0 $primary-light-color, 5px 0 0 $primary-light-color !important;
}
.flatpickr-day.inRange {
    background-color: $primary-light-color !important;
    border-color: transparent !important;
}
.flatpickr-monthSelect-month.selected {
    background-color: $primary-light-color !important;
}

.flatpickr-monthSelect-theme-dark {
    .flatpickr-months {
        .flatpickr-month {
            border-radius: 4px !important;
        }
    }

    .flatpickr-innerContainer {
        display: none;
    }
}
