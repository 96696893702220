.m-application {
    .a-button {
        margin-top: 30px;
    }

    &__descriptionAndImage {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;

        @include media-down(l) {
            flex-direction: column;
            align-items: center;
            row-gap: 20px;
        }
    }

    &__glassBackground {
        background-color: rgba(255, 255, 255, 0.35);
        backdrop-filter: blur(8px);
        padding: 40px 30px;
        height: 100%;
    }

    &__wrapper {
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    &.-single {
        color: $black;
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 30px;
        cursor: pointer;

        &:hover {
            .m-application__content--wrapper {
                border-color: $secondary-color;
            }
        }
    }

    &__hero {
        height: 200px;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            height: 100%;
            background: linear-gradient(
                180deg,
                rgba(25, 33, 50, 0) 0%,
                #192132 100%
            );
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: 0.2s;
        }

        &--subtitle {
            color: $white;
            position: absolute;
            bottom: 15px;
            left: 20px;
            z-index: 20;
            cursor: pointer;
        }
    }

    &__content {
        padding: 20px;
        transition: 0.2s;

        &--description {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;

            & + p {
                padding-top: 20px;
                border-top: 1px solid $gray-2;
            }
        }

        &--wrapper {
            height: 100%;
            border: 1px solid $gray-3;
            border-radius: 3px;
            background: $white;
            transition: 0.2s;
        }
    }

    &__search {
        .m-inputGroup {
            svg {
                top: 25px;
            }
        }
    }

    &__campaignPage {
        position: relative;
        color: $white;
        padding: 5px 0 165px 0;
        overflow: hidden;
   
        &__text {
            display: flex;
            flex-direction: column;
            margin-top: 1.5rem;

            &--title {
                margin-top: 15px;
                color: $white;               
                
                @include media-down(l) {
                    max-width: 250px;
                }

                @include media-down(xs) {
                    max-width: 150px;
                }
            }

            @include media-down(l) {
                max-width: 250px;
            }

            @include media-down(xs) {
                max-width: 150px;
            }
        }

        &__image {
            display: flex;
            flex-direction: column;
        }

        .a-button {
            margin-top: 20px;
            background-color: $white;
            color: $black;
            border: 1px solid $white;

            &:hover {
                background-color: transparent !important;
                color: $white;
            }
        }
    }

    &__purpose {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        width: 300px;
    }
}
