.m-carousel {
    overflow-x: hidden;
    margin: 0 auto;

    &__hero {
        position: relative;
        min-height: 670px;

        @include media-down(xl) {
            min-height: 550px;
        }
        @include media-down(l) {
            min-height: 370px;
        }
        @include media-down(m) {
            min-height: 270px;
        }
        @include media-down(s) {
            min-height: 220px;
        }
        @include media-down(xs) {
            min-height: 170px;
        }

        img {
            width: 100%;
            height: 670px;
            object-fit: cover;
            
            @include media-down(xl) {
                height: 550px;
            }
            @include media-down(l) {
                height: 370px;
            }
            @include media-down(m) {
                height: 270px;
            }
            @include media-down(s) {
                height: 220px;
            }
            @include media-down(xs) {
                height: 170px;
            }
        }

        &--single {
            width: 100%;
            opacity: 0;
            position: absolute;
            &.active {
                animation: fadeinout 0.4s;
                opacity: 1;
            }
        }

        &.-noImages {
            background-color: $gray-3;
        }
    }

    &__thumbnails {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        margin-top: 20px;
        scroll-snap-type: x mandatory;
        overflow-x: scroll;
        scroll-behavior: smooth;
        padding-bottom: 15px;
        @include scrollbar(5px, $secondary-color);

        @include media-down(l) {
            margin-left: 0px;
            margin-right: 0px;
            column-gap: 1rem;
        }

        &--thumb {
            padding: 0 15px;
            flex: 0 0 auto;
            height: 90px;

            @include media-down(l) {
                padding: 0px;
            }

            @include media-down(xs) {
                max-width: 33%;
            }

            &.active {
                img {
                    border: 2px solid $secondary-color;
                }
            }

            &.-noImages {
                img {
                    background-color: $gray-3;
                    border: none;
                }
            }
        }

        img {
            border: 2px solid transparent;
            width: 100%;
            height: 90px;
            object-fit: cover;
        }
    }

    &__actions {
        position: absolute;
        top: 50%;
        border: none;
        z-index: 2;
        background: none;
        outline: none;

        &:hover {
            cursor: pointer;
        }

        &:active,
        &:focus {
            outline: none;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            height: 20px;
            border-width: 0 0 3px 3px;
            border-style: solid;
            border-color: $white;
            box-shadow: -5px 5px 5px rgba($black, $alpha: 0.38);
        }

        &.-prev {
            left: 12px;

            &::before {
                transform: rotate(45deg);
            }
        }

        &.-next {
            right: 20px;

            &::before {
                transform: rotate(225deg);
            }
        }
    }
}

@keyframes fadeinout {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
