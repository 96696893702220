.m-about {
    &__image {
        display: flex;
        flex-direction: column;
        @include media-down(l) {
            order: 2;
        }
        
        @include media-down(s) {
            margin-left: 15px;
        }
    }

    &__text {
        padding: 25px 0;

        @include media-up(l) {
            max-width: 400px;
        }

        @include media-down(m) {
            padding-top: 0;
            padding-bottom: 25px;
        }

        .-title {
            margin-top: 10px;
            font-size: 32px;
        }

        .-description {
            margin-top: 17px;
        }

        .-list {
            position: relative;
            display: flex;
            flex-direction: column;
            margin: 30px 0;
            padding: 30px 0;

            @include media-down(m) {
                margin: 20px 0;
                padding: 20px 0;
            }
        }

        .-item {
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 20px;
            line-height: 30px;

            &.-margin {
                margin-top: 8px;
            }

            svg {
                margin-top: 4px;
                margin-right: 5px;
                overflow: visible;
            }
        }
    }
}
