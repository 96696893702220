.m-hero {
    margin-top: 60px;
    padding-top: 60px;
    padding-bottom: 100px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 460px;
    background-image: url('../../../images/hero_bg.png');

    @include media-down(xl) {
        padding-top: 20px;
    }

    @include media-down(m) {
        padding-bottom: 375px;
        background-image: url('../../../images/hero_small.png');
    }

    &__text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &--description {
            margin-top: 20px;
            font-size: 18px;
            line-height: 29px;
        }

        &.-overlay {
            background-color: rgba(255, 255, 255, 0.35);
            backdrop-filter: blur(8px);
            padding: 40px 30px;
        }
    }

    .a-button {
        margin-top: 30px;
    }

    &.-application {
        background-image: url('../../../images/default-bg.jpg');
        background-size: cover;
        padding: 0;
        background-position: center;
        background-repeat: no-repeat;
    }

    &.-noTopMargin {
        margin-top: 0;
    }

    &.-list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        padding: 100px 0;

        p {
            margin-top: 20px;
            font-size: 18px;
            line-height: 1.6;
            color: $black;
        }
    }

    &.-fixed {
        height: 280px;
        background-image: url('../../../images/fixed-header.jpg');
        background-size: cover;
        padding: 0;
        background-position: center;
        background-repeat: no-repeat;
        margin-bottom: 5px;

        @include media-down(xxl) {
            background-position: 100% center;
        }
    }
}
