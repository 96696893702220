.m-introduction {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    @include media-down(l) {
        align-items: flex-start;
        text-align: left;
    }

    .-title {
        margin-top: 10px;
        font-size: 32px;
    }

    .-text {
        margin-top: 17px;
    }
}
