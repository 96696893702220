.m-voting {
    position: relative;
    display: flex;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        width: 93%;
        height: 100%;
        background-color: $white;
    }

    &__left {
        @include media-down(l) {
            margin-top: 35px;
        }

        &--numbered {
            display: flex;
            align-items: baseline;

            .-number {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;
                min-width: 40px;
                min-height: 40px;
                margin-right: 15px;
                border-radius: 50%;
                background-color: $number-bg;
                color: $number;
                font-weight: 700;
                font-size: 22px;
                line-height: 24px;
                text-align: center;

                &::after {
                    content: '';
                    position: absolute;
                    top: calc(100% + 10px);
                    width: 1px;
                    height: 150%;
                    border-left: 1px solid $black;
                }
            }

            .-title {
                font-weight: 600;
                font-size: 22px;
                line-height: 26px;

                @include media-down(m) {
                    font-size: 18px;
                    line-height: 22px;
                }
            }

            .-description {
                margin-top: 10px;
                max-width: 450px;

                &.-padding {
                    padding-bottom: 25px;
                }
            }

            &:last-child {
                .-number {
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }

    &__right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @include media-down(l) {
            order: -1;
        }

        &--title {
            margin-top: 15px;
        }

        &--description {
            margin-top: 20px;
        }
    }

    .a-button {
        margin-top: 30px;
    }

    &__groupedCheckbox {
        margin-top: 28px;
        display: flex;
        justify-content: space-between;
        row-gap: .5rem;
        white-space: nowrap;

        @include media-down(s) {
            margin-top: 15px;
            flex-direction: column;
        }
    }
}