.a-checkbox {
    display: flex;
    align-items: center;
    position: relative;
    width: fit-content;
    margin-top: 0;

    &:not(:last-of-type) {
        margin-bottom: 15px;
        margin-right: 10px;
    }

    &.-disabled {
        opacity: 0.7;

        label {
            color: #828a8d;
            cursor: default;
        }

        input {
            color: #828a8d;
        }
    }

    input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        pointer-events: none;

        &:checked {
            & + label {
                &::before {
                    background-color: $white;
                    border-color: $border-color;
                    background-image: url(../../../images/checkBlack.svg);
                }
            }
        }
    }

    label {
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        font-size: 13px;
        line-height: 16px;

        @include media-up(m) {
            &::before {
                width: 20px;
                min-width: 20px;
                height: 20px;
            }
        }

        &::before {
            content: '';
            display: inline-block;
            width: 18px;
            min-width: 18px;
            height: 18px;
            margin-right: 10px;
            border: 1px solid $border-color;
            border-radius: 3px;
            background-image: none;
            background-position: center;
            background-repeat: no-repeat;
            background-color: $white;
            transition: background-color 0.3s, border-color 0.3s;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        margin-top: 15px;
    }

    &__label {
        font-size: $font-size-small;
        line-height: 16px;
        font-weight: 600;
        margin-bottom: 10px;
    }
}
